<script>
import DashboardCard from "./DashboardCard.vue";
import axios from "axios";

import moment from "moment";

export default {
    name: "DashboardAuditorsDaysPercentages",
    components: {
        DashboardCard,
    },
    data() {
        return {
            dasyPerAuditor: {},
            loadingDaysPerAuditor: false,
            nextNinetyDays: 90,
        };
    },
    created() {
        this.loadingDaysPerAuditor = true;
        axios
            .get("dashboard/auditors/days-percentages-by-auditor", {
                headers: {
                    Authorization:
                        "Bearer " + this.$store.getters["user/getToken"],
                },
            })
            .then((response) => {
                this.dasyPerAuditor = response.data.data;
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {
                this.loadingDaysPerAuditor = false;
            });
    },
};
</script>

<template>
    <DashboardCard>
        <template #header> Porcentajes de días por auditor </template>

        <template #subheader>
            Cálculos realizados para los próximos {{ nextNinetyDays }} días
            <div class="flex flex-row my-2 gap-4 h-48">
                <div class="flex gap-2">
                    <span class="block h-4 w-4 rounded-full bg-tuvred"></span>No
                    disponibles
                </div>
                <div class="flex gap-2">
                    <span class="block h-4 w-4 rounded-full bg-tuvblue"></span
                    >Agendados
                </div>
                <div class="flex gap-2">
                    <span class="block h-4 w-4 rounded-full bg-tuvgreen"></span
                    >Disponibles
                </div>
            </div>
        </template>

        <template #body>
            <ul class="h-60 overflow-y-auto">
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="auditor in dasyPerAuditor"
                    :key="auditor.id"
                    v-title
                    title="Ver calendario"
                >
                    <router-link
                        class="block col-span-3 hover:text-slate-600 w-full no-underline text-slate-600"
                        :to="{
                            name: 'usercalendar',
                            params: { id: auditor.id },
                        }"
                    >
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                        >
                            {{ auditor.persona.last_name }},
                            {{ auditor.persona.first_name }}
                        </h4>
                        <div class="flex flex-row nowrap gap-0">
                            <div
                                class="m-0 bg-tuvred text-slate-50 text-xs px-2 py-1 text-left"
                                :style="`width: ${
                                    (auditor.not_availabilities_next_three_months_count /
                                        nextNinetyDays) *
                                    100
                                }%;`"
                                v-if="
                                    auditor.not_availabilities_next_three_months_count
                                "
                            >
                                {{
                                    auditor.not_availabilities_next_three_months_count
                                }}
                            </div>
                            <div
                                class="m-0 bg-tuvblue text-slate-50 text-xs px-2 py-1 text-center"
                                :style="`width: ${
                                    (auditor.auditss_current_year_count /
                                        nextNinetyDays) *
                                    100
                                }%;`"
                                v-if="auditor.auditss_current_year_count"
                            >
                                {{ auditor.auditss_current_year_count }}
                            </div>
                            <div
                                class="m-0 bg-tuvgreen text-tuvblack text-xs px-2 py-1 text-left"
                                :style="`width: ${
                                    ((nextNinetyDays -
                                        (auditor.not_availabilities_next_three_months_count +
                                            auditor.auditss_current_year_count)) /
                                        nextNinetyDays) *
                                    100
                                }%;`"
                            >
                                {{
                                    nextNinetyDays -
                                    (auditor.not_availabilities_next_three_months_count +
                                        auditor.auditss_current_year_count)
                                }}
                            </div>
                        </div>

                        <!-- <p class="font-normal group-hover:text-slate-500 text-slate-400">Cantidad de certificaciones: <span class="text-slate-600 font-bold">{{ auditor.certificationsCount }}</span></p> -->
                    </router-link>
                </li>
            </ul>
        </template>
    </DashboardCard>
</template>
