<script>
import { ref, computed } from "vue";
import { useUserStore } from "../stores/user";
import { useUsersStore } from "../stores/users";
import { useRolesStore } from "../stores/roles";
import { useNormsStore } from "../stores/norms";

import AppModal from "../components/AppModal.vue";
import AppButton from "../components/AppButton.vue";
import { useNorms } from "../composables/norm";

import AuditorsCalendar from "../components/AuditorsCalendar.vue";

import { mapState, mapActions, mapGetters } from "vuex";

import Modal from "../../views/spa/_elements/Modal.vue";

import ModalNewService from "../../views/spa/ModalNewService.vue";
import OperationsCalendar from "../../views/spa/OperationsCalendar.vue";
import axios from "axios";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import PageHomeHeader from "../components/PageHomeHeader.vue";
import { useRole } from "../composables/role";

export default {
    setup() {
        const usersStore = useUsersStore();

        const normsStore = useNormsStore();
        normsStore.getNorms();

        usersStore.getUsers();
        const searchText = ref("");
        const QueryAvailabilitiesModal = ref(null);

        const userStore = useUserStore();
        const isOperator = userStore.isOperator;

        const roleStore = useRolesStore();

        const queryAvailabilitiesForm = ref({
            norm_id: "",
            accreditation_id: "",
            eac_id: "",
            errors: {
                exists: false,
                errors: [],
                type: "",
            },
        });

        const QueryAvailabilitiesModalOpen = () => {
            QueryAvailabilitiesModal.value.openModal();
        };

        const { norms, norm } = useNorms(queryAvailabilitiesForm.value.norm_id);

        return {
            searchText,
            queryAvailabilitiesForm,
            QueryAvailabilitiesModal,
            QueryAvailabilitiesModalOpen,
            norm,
            norms,
            isOperator,
            userStore, // mientras migración de option a composition
            roleStore,
        };
    },
    name: "OperadorInicio",
    components: {
        AppModal,
        AppButton,
        Modal,
        ModalNewService,
        OperationsCalendar,
        AuditorsCalendar,
        PageHomeHeader,
    },
    data() {
        return {
            // search services
            prevSearchText: "",
            filteredServices: [],
            filteredServicesIndex: 0,
        };
    },
    computed: {
        ...mapState("entities/user", {
            userToken: (state) => state.token,
        }),
        isOperator() {
            // migración option a composition
            return this.userStore.isOperator;
        },
        // norms() {
        //     return this.$store.state.norms.norms;
        // },
        accreditations() {
            return this.$store.state.accreditations.accreditations;
        },
        eacs() {
            return this.$store.state.eacs.eacs;
        },

        type() {
            return "alert-" + this.alert.type;
        },
        ...mapGetters({
            servicesTemp: "servicesTemp/getServicesTemp",
            clients: "clients/clients",
        }),
        search() {
            return this.searchText;
        },
    },
    methods: {
        ...mapActions({
            /** OK */
            // getUsers: "users/getUsers",
            storeClients: "clients/storeClients",
            queryAvailabilities: "operationsCalendar/queryAvailabilities",
            loadInvoices: "invoices/loadInvoices",

            /** Chequear */

            storeServiceTemp: "servicesTemp/storeServiceTemp",

            updateServiceTemp: "servicesTemp/updateServiceTemp",
            storeAccreditations: "accreditations/storeAccreditations",
            // 'storeAuditors': 'auditors/storeAuditors',
            storeCertifications: "certifications/storeCertifications",
            storeEacs: "eacs/storeEacs",
            loadRisks: "risks/loadRisks",
        }),

        searchServices() {
            this.$refs.operationsCalendar.searchServices(this.searchText);
        },
        clearSearchServices() {
            this.searchText = "";
            this.$refs.operationsCalendar.clearSearchServices();
        },
        queryAvailabilitiesFn() {
            if (this.queryAvailabilitiesForm.norm_id) {
                var norm_id = this.queryAvailabilitiesForm.norm_id;
            }

            if (this.queryAvailabilitiesForm.accreditation_id) {
                var accreditation_id =
                    this.queryAvailabilitiesForm.accreditation_id;
            }

            var eac_id = this.queryAvailabilitiesForm.eac_id;

            this.queryAvailabilities(this.queryAvailabilitiesForm)
                .then((response) => {
                    this.$refs.queryAvailabilitiesModal.closeModal();
                    this.clearQueryAvailabilitiesForm();
                })
                .catch((error) => {
                    console.log("error component: ", error);
                    this.queryAvailabilitiesForm.errors.exists = true;
                    this.queryAvailabilitiesForm.errors.errors =
                        error.data.errors;
                });
        },
        clearQueryAvailabilitiesForm(errors) {
            if (errors == "errors") {
                this.queryAvailabilitiesForm.errors.exists = false;
                this.queryAvailabilitiesForm.errors.errors = [];
                this.queryAvailabilitiesForm.errors.type = "";
            } else {
                this.queryAvailabilitiesForm.norm_id = "";
                this.queryAvailabilitiesForm.accreditation_id = "";
                this.queryAvailabilitiesForm.eac_id = "";
                this.queryAvailabilitiesForm.errors.exists = false;
                this.queryAvailabilitiesForm.errors.errors = [];
                this.queryAvailabilitiesForm.errors.type = "";
            }
        },
    },
    created() {
        // this.getUsers();
        this.storeAccreditations();
        this.storeCertifications();
        this.storeEacs();
        this.loadRisks();

        this.loadInvoices();

        if (this.isOperator) {
            this.roleStore.getRoles();
        }
    },
};
</script>

<template>
    <div v-if="isOperator">
        <div class="mx-8">
            <PageHomeHeader
                @open-search-modal="QueryAvailabilitiesModalOpen"
                v-model:search="searchText"
                @searchServices="searchServices()"
                @clearSearchServices="clearSearchServices()"
            />
            <operations-calendar
                :search="searchText"
                ref="operationsCalendar"
            ></operations-calendar>
        </div>

        <!-- <AppModal ref="QueryAvailabilitiesModal">
            <template #title>Consultar disponibilidad de auditores</template>
            <template #subtitle> Prueba de title </template>
            <template #body>
                <div class="grid grid-cols-1 xl:grid-cols-3 gap-4 text-xs">
                    <div class="flex flex-col">
                        <label class="text-tuvblack" for="norm">Norma</label>
                        <select
                            class="appearance-none bg-white placeholder:text-slate-400 border border-tuvgray placeholder:italic text-xs pl-10 self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                            v-model="queryAvailabilitiesForm.norm_id"
                            name="norm"
                            id="norm"
                        >
                            <option
                                class="text-slate-400 italic disabled:opacity-50"
                                value=""
                                selected
                                disabled
                            >
                                Seleccione una norma
                            </option>
                            <option
                                v-for="norm in norms"
                                :key="norm.id"
                                :value="norm.id"
                            >
                                {{ norm.norm }}
                            </option>
                        </select>
                    </div>

                    <div class="flex flex-col">
                        <label class="text-tuvblack" for="accreditation"
                            >Acreditación</label
                        >
                        <select
                            class="bg-white placeholder:text-slate-400 border border-tuvgray placeholder:italic text-xs pl-10 self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                            v-model="queryAvailabilitiesForm.accreditation_id"
                            name="accreditation"
                            id="accreditation"
                        >
                            <option
                                class="text-slate-400 italic"
                                value=""
                                selected
                                disabled
                            >
                                Seleccione una acreditación
                            </option>
                            <option
                                v-for="accreditation in accreditations"
                                :key="accreditation.id"
                                :value="accreditation.id"
                            >
                                {{ accreditation.accreditation }}
                            </option>
                        </select>
                    </div>

                    <div class="flex flex-col">
                        <label class="text-tuvblack" for="eac">Alcance</label>
                        <select
                            class="bg-white placeholder:text-slate-400 border border-tuvgray placeholder:italic text-xs pl-10 self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                            v-model="queryAvailabilitiesForm.eac_id"
                            name="eac"
                            id="eac"
                        >
                            <option
                                class="text-slate-400 italic"
                                value=""
                                selected
                                disabled
                            >
                                Seleccione un alcance
                            </option>
                            <option
                                v-for="eac in eacs"
                                :key="eac.id"
                                :value="eac.id"
                            >
                                {{ eac.eac }}
                            </option>
                        </select>
                    </div>
                    {{ norm }}

                    {{ queryAvailabilitiesForm.norm_id }}
                </div>
            </template>
            <template #actions>
                <AppButton
                    class="bg-tuvblue text-white"
                    @@click="queryAvailabilitiesFn()"
                    >Consultar</AppButton
                >
            </template>
            <template #footer></template>
        </AppModal>

        <modal ref="addEventToCalendar">
            <template v-slot:header>
                <h2 class="text-lg font-bold text-slate-600">
                    Crear auditoría
                </h2>
            </template>

            <template #body>
                <modal-new-service></modal-new-service>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-xs text-slate-600 active:shadow-none transition-shadow ease-out duration-100 mr-2" @click="$refs.queryAvailabilitiesModal.closeModal(); clearQueryAvailabilitiesForm();">
                    <font-awesome-icon icon="fa-duotone fa-xmark" class="text-xl text-slate-600 md:mr-2" />
                    <span class="hidden md:inline-block">Cancelar</span>
                </button>
                    <button
                        class="bg-blue-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out"
                        @click="nextStep()"
                    >
                        <font-awesome-icon icon="fa-duotone fa-magnifying-glass" class="text-xl text-white-600 md:mr-2" />
                        <span class="hidden md:inline-block">Siguiente</span>
                    </button>
                </div>
            </template>
        </modal> -->
    </div>

    <div v-else>
        <AuditorsCalendar></AuditorsCalendar>
    </div>
</template>
