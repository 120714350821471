<script>
import DashboardCard from "./DashboardCard.vue";
import moment from "moment";

export default {
    name: "DashboardClientsWithoutCertificates",
    components: {
        DashboardCard,
    },
    props: {
        clientsWithoutCertificates: {
            required: true,
        },
    },
    data() {
        return {
            delay: {
                cgcre: 30,
                dakks: 60,
            },
        };
    },
    methods: {
        diffDays(client) {
            return moment().diff(
                moment(client.first_opened_certification_phase.scheduled_at),
                "days"
            );
        },
    },
    computed: {
        // clientsWithoutCertificate(){
        //     let certifications = this.$store.state.certifications.certifications;
        //     let thirtyDays = moment().subtract(this.delay.cgcre, 'days');
        //     let sixtyDays = moment().subtract(this.delay.dakks, 'days');
        //     let today = moment();
        //     let filteredCertifications = certifications.filter(certification =>
        //                                             certification.first_opened_certification_phase &&
        //                                             certification.first_opened_certification_phase.scheduled_at &&
        //                                             certification.first_opened_certification_phase.emit_cert != 0 &&
        //                                             certification.first_opened_certification_phase.has_cert == 0 &&
        //                                             moment(certification.first_opened_certification_phase.scheduled_at).isBefore(today));
        //     filteredCertifications.sort(function( a, b ) {
        //         if( a.first_opened_certification_phase.scheduled_at > b.first_opened_certification_phase.scheduled_at){
        //             return 1;
        //         }
        //         if( a.first_opened_certification_phase.scheduled_at < b.first_opened_certification_phase.scheduled_at){
        //             return -1;
        //         }
        //         return 0;
        //     });
        //     return filteredCertifications;
        // }
    },
};
</script>

<template>
    <DashboardCard>
        <template #header> Clientes sin certificado </template>

        <template #subheader>
            Parámetros: {{ delay.cgcre }} días para acreditaciones CGCRE,
            {{ delay.dakks }} días para acreditaciones DAKKs
        </template>

        <template #body>
            <ul
                class="h-60 overflow-y-auto"
                v-if="clientsWithoutCertificates.length > 0"
            >
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="client in clientsWithoutCertificates"
                    :key="client.id"
                >
                    <span class="col-span-3" v-if="diffDays(client) > 0">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                        >
                            {{ client.client.account_name }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            {{ client.norm.norm }} -
                            {{ client.accreditation.accreditation }}<br />
                            <span
                                class="text-rose-500"
                                v-if="client.condition == 'expired'"
                                >{{ diffDays(client) }} días desde
                                finalización</span
                            ><span v-else
                                >{{ diffDays(client) }} días desde
                                finalización</span
                            >
                        </p>
                    </span>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
        </template>
    </DashboardCard>
</template>
